<template>
  <div>
    <!-- list table data -->
    <b-table
      responsive
      striped
      hover
      selectable
      id="my-table"
      :items="data"
      :fields="list_product.fields"
      select-mode="single"
      @row-selected="row_selected"
      :show-empty="allRow === 0"
      :busy.sync="loading"
    >
      <template v-slot:cell(no)="data">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" :class="mx_gear_color(data.item.status)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M18.622 9.75h.128a2.25 2.25 0 110 4.5h-.065a.488.488 0 00-.446.295.488.488 0 00.089.54l.045.044a2.25 2.25 0 11-3.183 3.184l-.04-.04a.49.49 0 00-.545-.094.486.486 0 00-.295.444v.127a2.25 2.25 0 11-4.5 0 .524.524 0 00-.363-.514.485.485 0 00-.532.092l-.044.045a2.25 2.25 0 11-3.184-3.183l.04-.04a.49.49 0 00.094-.545.486.486 0 00-.443-.295H5.25a2.25 2.25 0 110-4.5.524.524 0 00.514-.363.485.485 0 00-.092-.532l-.045-.044A2.25 2.25 0 118.81 5.687l.04.04c.142.139.355.177.537.097l.108-.023a.486.486 0 00.255-.423V5.25a2.25 2.25 0 114.5 0v.065c0 .194.117.37.303.449.182.08.395.042.532-.092l.044-.045a2.25 2.25 0 113.184 3.183l-.04.04a.488.488 0 00-.097.537l.023.108a.486.486 0 00.423.255z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" fill="#000"/></g></svg>
      </template>

      <!-- name -->
      <template v-slot:cell(name)="data">
        <h6>{{ data.value }}</h6>
        <a
          :href="'http://' + data.item.domain"
          class="text-dark"
          target="_blank"
          >{{ data.item.domain }}</a
        >
      </template>

      <!-- pricing -->
      <template v-slot:cell(price)="data">
        <h6>{{ data.value }}</h6>
        {{ data.item.recurring }}
        <span v-if="type === 'domain'">Year<sup>(s)</sup></span>
      </template>

      <!-- duedate -->
      <template v-slot:cell(duedate)="data">
        {{ data.value }}
      </template>

      <!-- button -->
      <template v-slot:cell(status)="data">
        <b-link :to="get_link(data.item)">
          <b-button
            style="color: white; text-transform: capitalize"
            :variant="mx_status_color(data.value)"
            block
            >{{ data.value }}
          </b-button>
        </b-link>
      </template>

      <!--busy state-->
      <template #table-busy>
        <div class="text-center text-warning my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { productMixin } from "@/common/mixins/product/product-mixin.js";
export default {
  name: "slotProductShow",
  mixins: [productMixin],
  props: {
    /**
     * ------------------------------------------
     * data for body table
     * ------------------------------------------
     */
    data: {
      type: Array,
      required: true
    },

    /**
     * -------------------------------------------
     * type untuk membedakan product (domain, hosting, vps etc)
     * -------------------------------------------
     */
    type: {
      type: String,
      required: true
    },

    /**
     * --------------------------------------------------
     * set proses loading table
     * --------------------------------------------------
     */
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentpage: 1
    };
  },
  methods: {
    /**
     * -------------------------------------------------
     * ketika klik select on row
     * -------------------------------------------------
     */
    row_selected(item) {
      this.$router.push(this.get_link(item[0]));
    },

    /**
     * -------------------------------------------------
     * generate link dari type & id / when click button
     * -------------------------------------------------
     */
    get_link: function(item) {
      const id = item.id;
      return `/product/${this.type}/${id}`;
    }
  },
  computed: {
    allRow() {
      return this.data.length;
    }
  }
};
</script>
