<template>
  <div>
    <RWCard>
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M22 17v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4h4.28l.543 1.632A2 2 0 008.721 20h6.661a2 2 0 001.789-1.106L18.118 17H22z" fill="#000"/><path d="M2.563 15l3.364-5.98A2 2 0 017.67 8h8.66a2 2 0 011.743 1.02L21.437 15h-3.319a2 2 0 00-1.789 1.106L15.382 18H8.721l-.544-1.632A2 2 0 006.279 15H2.563z" fill="#000" opacity=".3"/><path d="M14.888 2.83H12.93V.824a.5.5 0 00-.5-.5h-1.023a.5.5 0 00-.5.5v2.008H8.95a.5.5 0 00-.381.823l2.968 3.505a.5.5 0 00.763 0l2.968-3.505a.5.5 0 00-.381-.823z" fill="#000" fill-rule="nonzero"/></g></svg>
          Email Product
        </h3>
      </template>
      <template v-slot:body>
        <div v-if="loading.banner">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </div>
        <div v-else>
          <b-carousel
            v-if="success.banner"
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            fade
            indicators
            img-width="1024"
            img-height="300"
          >
            <b-carousel-slide
              v-for="(banner, index) in xhr.banner"
              :key="index"
              :caption="banner.caption"
              :img-src="banner.image"
            ></b-carousel-slide>
          </b-carousel>
          <b-alert variant="danger" show v-else>{{
            xhr.banner.message
          }}</b-alert>
        </div>
      </template>
    </RWCard>

    <!-- list email -->
    <b-card>
      <template v-slot:header>
        <b-form inline @submit.stop.prevent="search">
          <label class="sr-only">Domain Name</label>
          <b-input
            id="inline-form-input-name"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="eq. mydomain.id"
            v-model="form_search.domain"
            autocomplete="off"
          ></b-input>

          <label class="sr-only">Username</label>
          <b-row>
            <b-col cols="8">
              <b-form-select
                class="my-2 ml-sm-4"
                :options="status"
                v-model="form_search.status"
                @change="search()"
              ></b-form-select>
            </b-col>
            <b-col cols="4">
              <b-button type="submit" class="my-2" variant="primary"
                >Find</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </template>

      <!-- table -->
      <div v-if="loading.product">
        <v-skeleton-loader
          type="table-thead, table-row-divider@4, table-tfoot"
        ></v-skeleton-loader>
      </div>
      <div v-else>
        <div v-if="success.product">
          <div v-if="xhr.product.length !== 0">
            <b-skeleton-table
              :rows="pagination.limit"
              :columns="5"
              :table-props="{ striped: true }"
              v-if="loading.pagination"
            ></b-skeleton-table>
            <ShowProduct v-else :data="xhr.product" type="email"></ShowProduct>

            <!-- pagination -->
            <b-pagination
              v-model="pagination.current"
              :total-rows="pagination.allCount"
              :per-page="pagination.limit"
              @change="handlePagination"
              align="right"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
            ></b-pagination>
          </div>
          <div v-else>
            <b-alert variant="warning" show>Product not found</b-alert>
          </div>
        </div>
        <b-alert variant="danger" show v-else>{{
          xhr.product.message
        }}</b-alert>
      </div>
      <!-- endtable -->
    </b-card>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import ShowProduct from "@/views/pages/product/slot/slotProductShow";
import { breadcrumb, ApiURL, util } from "@/common/mixins/general.js";

export default {
  name: "product-email-list",
  mixins: [breadcrumb, ApiURL, util],
  components: { RWCard, ShowProduct },
  data() {
    return {
      loading: {
        banner: true,
        product: true,
        pagination: false
      },
      xhr: {
        banner: null,
        product: []
      },
      form_search: {
        domain: "",
        status: "All"
      },
      status: [
        "All",
        "Active",
        "Cancelled",
        "Pending",
        "Suspended",
        "Terminated"
      ],
      pagination: {
        limit: 5,
        offset: 0,
        current: 1,
        allCount: 0
      },
      success: {
        banner: false,
        product: false
      }
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * get banner iklan email
     * -----------------------------------------
     */
    get_banner() {
      this.http
        .get(this.end.email.banner)
        .then(({ data }) => {
          this.xhr.banner = data.data.banner;
          this.success.banner = true;
        })
        .catch(error => {
          this.xhr.banner = error;
        })
        .finally(() => {
          this.loading.banner = false;
        });
    },

    /**
     * -----------------------------------------
     * generate /submit pencarian (domain, status)
     * -----------------------------------------
     */
    search() {
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.loading.product = true;
      this.get_service();
    },

    /**
     * -----------------------------------------
     * get table product /service hosting
     * -----------------------------------------
     */
    get_service() {
      let query = this.mx_ObjectToQuery(this.query_request, "?");

      this.http
        .get(this.end.email.index, query)
        .then(({ data }) => {
          this.xhr.product = data.data.service.record;
          this.pagination.allCount = data.data.service.count;
          this.success.product = true;
        })
        .catch(error => {
          this.xhr.product = error;

          // handle jika ketika next page ada error
          let current = this.pagination.current;
          this.pagination.current = current !== 1 ? current - 1 : 1;
        })
        .finally(() => {
          this.loading.product = false;
          this.loading.pagination = false;
        });
    },

    /**
     * ------------------------------------------------
     * handle pagination next/prev
     * ------------------------------------------------
     */
    handlePagination: function(page) {
      this.loading.pagination = true;
      this.pagination.offset = (page - 1) * this.pagination.limit;

      this.get_service();
    }
  },
  computed: {
    /**
     * -------------------------------------------------
     * generate query request data
     * -------------------------------------------------
     */
    query_request: function() {
      let query = {};
      query.limit = this.pagination.limit;
      query.offset = this.pagination.offset;

      if (this.form_search.status !== "All") {
        query.status = this.form_search.status;
      }
      if (this.form_search.domain !== "") {
        query.search = this.form_search.domain;
      }
      return query;
    }
  },
  mounted() {
    this.mx_breadcrumbEmail();
    this.get_banner();
    this.get_service();
  }
};
</script>
